import React from "react";
import Layout from "../Components/Layout/Layout";
import "../Style/Roofing.css";
import roofingImg10 from "../Assets/roofing10.jpg";
import roofingImg9 from "../Assets/roofing9.jpg";
import roofingImg7 from "../Assets/roofing7.jpg";
import roofingImg6 from "../Assets/roofing6.jpg";
import roofingImg5 from "../Assets/roofing5.jpg";
import roofingImg3 from "../Assets/roofing3.jpg";
import roofingImg2 from "../Assets/roofing2.jpg";
import roofingImg18 from "../Assets/roofing18.jpg";
import roofingImg17 from "../Assets/roofing17.jpg";
import roofingImg16 from "../Assets/roofing16.jpg";
import roofingImg15 from "../Assets/roofing15.jpg";
import roofingImg14 from "../Assets/roofing14.jpg";
import roofingImg13 from "../Assets/roofing13.jpeg";
import roofingImg12 from "../Assets/roofing12.jpeg";
import roofingImg11 from "../Assets/roofing11.jpg";
import roofingImg1 from "../Assets/roofing1.jpg";

function Roofing() {
  return (
    <Layout title={"Elegant Linings-Roofing"}>
      <section style={{ paddingTop: "7rem" }}>
        <div className="pt-5 " style={{ backgroundColor: "gray" }}>
          <div className=" px-5 pb-2  ">
            <h4
              style={{ fontWeight: "700", color: "white" }}
              className="pt-3 px-5 "
            >
              Roofing
            </h4>
          </div>
        </div>
      </section>
      <section
        className="pt-5 pb-5 image-roofing"
        style={{ minHeight: "65vh" }}
      >
        <div className="container  p-5">
          <div className="row">
            <h5
              className="pb-4 text-center"
              style={{ lineHeight: "1.9em", letterSpacing: "0.3px" }}
            >
              Flat Roofs
            </h5>

            <div className="col-md-12 text-center">
              <div
                style={{
                  fontSize: "1em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  TECNOPOL systems are ideal for waterproofing and protecting
                  any project, regardless of its geometry, surface material or
                  required finish. The quality of the system applied, whether
                  TECNOCOAT or DESMOPOL, is recognised worldwide thanks to the
                  numerous certifications and approvals it holds (EOTA, BBA, EN,
                  ISO...). Tecnopol's technical team has developed a system to
                  satisfy all the professionals involved in the construction
                  process: designers, engineers, architects, builders,
                  applicators and end users.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5">
        <div className="container  p-5">
          <div className="row">
            <div className="col-md-6 ">
              <div className="pt-5 ">
                <h6
                  className="pb-4"
                  style={{
                    fontWeight: "bolder",
                    color: "gray",
                    textTransform: "uppercase",
                  }}
                >
                  Renovation of flat roofs and terrace
                </h6>

                <p
                  style={{
                    fontSize: "0.9em",
                    lineHeight: "1.9em",
                    letterSpacing: "0.3px",
                  }}
                >
                  The renovation of older flat roofs requires effective and hard
                  wearing solutions. TECNOPOL continuous systems provide
                  excellent results without the need to remove the existing
                  paving or flooring surface. In addition, the nature of these
                  finishes makes them ideal for use on older roofs which are
                  hardly ever perfectly smooth or flat. Where the renovation
                  also requires thermal insulation we can provide one of the
                  best systems on the market providing optimum results in terms
                  of low heat conductivity.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <img
                  src={roofingImg1}
                  width={"100%"}
                  height={"100%"}
                  alt="roofingImg1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5">
        <div className="container text-center p-5">
          <div className="row">
            <div className="col-lg-12">
              <h5
                className=""
                style={{ fontWeight: "bolder", textTransform: "uppercase" }}
              >
                Roof Gardens
              </h5>

              <div
                className="pt-5"
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Rooftop systems are a very useful systems that wear the top of
                  the buildings and provide multiple actions such as absorbing
                  rainwater, providing insulation, creating an habitat for
                  wildlife and decreasing stress of the people, providing a more
                  aesthetically pleasing landscape, and helping to lower urban
                  air temperatures and mitigate the heat island effect. All the
                  concrete structures need to be protected from the rainwater,
                  of course. In this case, using vegetation as a “ green natural
                  finishing”, its need to be protected from the irrigation water
                  too. wearing course (asphalt), avoiding the risk of damage,
                  quickly, efficiently and therefore reducing the costs of
                  execution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5 image-roofing-second">
        <div className="container text-start p-5">
          <div className="row">
            <div className="col-lg-12 pt-5 pb-5">
              <h5 style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
                Walls and Facades
              </h5>

              <div
                className="pt-4"
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Thermal insulators are materials characterized by their high
                  thermal resistance capacities. Thermal insulators work by
                  preventing the flow of heat to a greater degree than other
                  construction components. All thermal insulators have their own
                  unique properties and it is important to select the right
                  product for each project. TECNOFOAM is a material with
                  extremely low thermal conductivity; in other words it provides
                  optimum insulation and thereby reduce energy consumption costs
                  for the future inhabitants of the building.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 pt-5 bg-light">
        <div className="container p-5">
          <div className="row">
            <h4 className="text-center pb-2">Gallery</h4>
            <div className="col-12 col-sm-6 col-lg-3 pt-4 ">
              <div className="">
                <img
                  src={roofingImg11}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg11"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={roofingImg12}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg12"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={roofingImg13}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg13"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={roofingImg14}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg14"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={roofingImg15}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg15"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={roofingImg16}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg16"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={roofingImg17}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg17"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={roofingImg18}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg18"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={roofingImg2}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg2"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={roofingImg3}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg3"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={roofingImg5}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg5"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={roofingImg6}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg6"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-4">
              <div className="">
                <img
                  src={roofingImg7}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg7"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-4">
              <div className="">
                <img
                  src={roofingImg9}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg9"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-4 pt-4">
              <div className="">
                <img
                  src={roofingImg10}
                  width={"100%"}
                  height={"250rem"}
                  alt="roofingImg10"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Roofing;
