import React from "react";
import Layout from "../Components/Layout/Layout";
import "../Style/ServicePage.css";
import serviceImg from "../Assets/service.jpg";
import serviceImg1 from "../Assets/service1.jpg";
import serviceImg3 from "../Assets/service3.jpg";
import serviceImg2 from "../Assets/service2.jpg";
import serviceImg4 from "../Assets/service4.jpg";
import serviceImg5 from "../Assets/service5.jpg";
import serviceImg6 from "../Assets/service6.jpg";
import serviceImg7 from "../Assets/service7.jpg";

function ServicePage() {
  return (
    <Layout title={"Elegant Linings-Service"}>
      <section
        style={{ paddingTop: "7em", backgroundColor: "rgb(17, 64, 69)" }}
      >
        <div className="pt-5 ">
          <div className="px-5 pb-2 pt-2 ">
            <h4
              style={{ fontWeight: "700", color: "white" }}
              className="pt-3 px-5"
            >
              Services
            </h4>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5 ">
        <div className="container p-5">
          <div className="row">
            <h5
              className="pb-4 text-center"
              style={{
                fontWeight: "900",
                textTransform: "uppercase",
                color: "gray",
              }}
            >
              We served Industries{" "}
            </h5>
            <hr className="pt-2 pb-2" />
            <div className="col-md-6">
              <div className="pt-4" style={{ lineHeight: "1.9em" }}>
                <li
                  style={{
                    textTransform: "uppercase",
                    fontWeight: "bolder",
                    color: "",
                  }}
                >
                  WATERPROOFING SYSTEMS
                </li>

                <div className="pt-4 ">
                  <p className="px-4" style={{ fontSize: "0.9em" }}>
                    Flat roofs, Renovation of flat roofs and terraces, Roof
                    gardens, Car park deck, Storage water tanks, Swimming pools,
                    fountains and water parks, Deck, engineering, Industrial
                  </p>
                </div>
                <div>
                  <li
                    style={{ textTransform: "uppercase", fontWeight: "bolder" }}
                    className="pt-4"
                  >
                    PROTECTION SYSTEMS
                  </li>
                  <div className="pt-3">
                    <li
                      className="px-4"
                      style={{ listStyle: "none", fontSize: "0.9em" }}
                    >
                      Asbestos encapsulation
                    </li>
                    <li
                      className="px-4"
                      style={{ listStyle: "none", fontSize: "0.9em" }}
                    >
                      Armour plating and defence
                    </li>
                    <li
                      className="px-4"
                      style={{ listStyle: "none", fontSize: "0.9em" }}
                    >
                      Industrial, Mines, Maritime use, Bed liners (vehicles)
                    </li>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="pt-4 ">
                <div className="row row-cols-1 row-cols-md-2 g-2">
                  <div className="col-6">
                    <div
                      className="card"
                      style={{ boxShadow: "0 10px 20px rgba(0, 0, 0, 0.4)" }}
                    >
                      <img
                        src={serviceImg}
                        className="card-img-top"
                        alt="service"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      className="card"
                      style={{ boxShadow: "0 10px 20px rgba(0, 0, 0, 0.4)" }}
                    >
                      <img
                        src={serviceImg1}
                        className="card-img-top"
                        alt="serviceImg1"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      className="card "
                      style={{ boxShadow: "0 10px 20px rgba(0, 0, 0, 0.4)" }}
                    >
                      <img
                        src={serviceImg3}
                        className="card-img-top"
                        alt="serviceImg3"
                      />
                    </div>
                  </div>
                  <div className="col-6">
                    <div
                      className="card"
                      style={{ boxShadow: "0 10px 20px rgba(0, 0, 0, 0.4)" }}
                    >
                      <img
                        src={serviceImg2}
                        className="card-img-top"
                        alt="serviceImg2"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5">
        <div className="container p-5">
          <div className="row">
            <div className="col-md-6 ">
              <li style={{ textTransform: "uppercase", fontWeight: "bolder" }}>
                INSULATION SYSTEMS
              </li>
              <p className="pt-2 px-4" style={{ fontSize: "0.9em" }}>
                Walls and facades, Passable floors, Non passable covers,
                Industrial storage tanks, Tubes and pipes
              </p>
            </div>
            <div className="col-md-6 ">
              <li style={{ textTransform: "uppercase", fontWeight: "bolder" }}>
                FLOORING SYSTEMS
              </li>
              <p className="pt-2 px-4" style={{ fontSize: "0.9em" }}>
                Industrial and commercial areas, Cleanrooms, Car parks
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container p-5 pt-4 pb-5">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-6 col-md-3 pt-4">
                  <div
                    className="card"
                    style={{ boxShadow: "0 10px 20px rgba(0, 0, 0, 0.4)" }}
                  >
                    <img src={serviceImg4} height={"150rem"} alt="" />
                  </div>
                </div>
                <div className="col-6 col-md-3 pt-4 ">
                  <div
                    className="card"
                    style={{ boxShadow: "0 10px 20px rgba(0, 0, 0, 0.4)" }}
                  >
                    <img src={serviceImg5} height={"150rem"} alt="" />
                  </div>
                </div>
                <div className="col-6 col-md-3 pt-4">
                  <div
                    className="card"
                    style={{ boxShadow: "0 10px 20px rgba(0, 0, 0, 0.4)" }}
                  >
                    <img src={serviceImg6} height={"150rem"} alt="" />
                  </div>
                </div>
                <div className="col-6 col-md-3 pt-4">
                  <div
                    className="card"
                    style={{ boxShadow: "0 10px 20px rgba(0, 0, 0, 0.4)" }}
                  >
                    <img src={serviceImg7} height={"150rem"} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-5">
        <div className="container p-5">
          <div className="row">
            <div className="col-12 col-lg-12 ">
              <h6 className="pb-4" style={{ fontWeight: "600" }}>
                Elegant Linings serves various application for different
                numerous industries:-
              </h6>

              <p style={{ fontSize: "0.9em", lineHeight: "1.9em" }}>
                Production facilities, Cold storage facility, Loading and
                Unloading docks, parking area, waste water tanks, Cannel, Parks,
                Roofs, Drinking water tanks, Pipes, Caravans, Shipping container
                coatings, Armoured vehicles, Fibreglass, Geotextiles, fenders,
                PU and polystyrene foam blocks, kiosk ,Hotels, Restaurants,
                Residential Building , Bakery, Cargo Logistics, Food and
                Beverages storage floors, food processing plant and facilities,
                food transportation liner for semi-Trucks, Trailers, walkways ,
                receiving area, Dry Process ,Equipment Wash, Laboratories ,
                Lobbies , Locker/Change Rooms, Mechanical Equipment Rooms ,
                Packaging, Warehouse , Water Room, Wet Processing, Swimming
                pool, ponds, fountains ,waste water treatment plant, sewages
                tank, oil tanks, ships, boats, Flat roofs, Renovation of flat
                roofs and terraces, Roof gardens, Car park deck, Storage water
                tanks, Swimming pools, fountains and water parks, Bridge Deck,
                Civil engineering
              </p>
              <p
                style={{ fontSize: "0.7em", fontWeight: "bolder" }}
                className="text-end"
              >
                Team Elegant Linings
              </p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ServicePage;
