import React from "react";
import Layout from "../Components/Layout/Layout";
import "../Style/BedlinersTransportation.css";
import Bedliners3 from "../Assets/Bedliners3.jpg";
import Bedliners5 from "../Assets/Bedliners5.jpg";
import Bedliners6 from "../Assets/Bedliners6.jpg";
import Bedliners7 from "../Assets/Bedliners7.jpg";
import Bedliners8 from "../Assets/Bedliners8.jpg";
import Bedliners9 from "../Assets/Bedliners9.jpg";
import Bedliners10 from "../Assets/Bedliners10.jpg";
import Bedliners11 from "../Assets/Bedliners11.1.png";
import Bedliners12 from "../Assets/Bedliners12.jpg";

function BedlinersTransportation() {
  return (
    <Layout title={"Elegant Linings-Bedliners and Transportation"}>
      <section style={{ paddingTop: "7rem" }}>
        <div className="pt-5 " style={{ backgroundColor: "gray" }}>
          <div className=" px-5 pb-2  ">
            <h4
              style={{ fontWeight: "700", color: "white" }}
              className="pt-3 px-5 "
            >
              Bedliners And Transportation
            </h4>
          </div>
        </div>
      </section>
      <section
        className="pt-5 pb-5 image-bedliners"
        style={{ minHeight: "65vh" }}
      >
        <div className="container pt-5 p-5">
          <div className="row">
           
            <h6
              className="pb-4 text-center"
              style={{ lineHeight: "1.9em", letterSpacing: "0.3px" }}
            >
              No one knows in the Middle East of vehicle protection better than
              the Elegant Linings sprayed-on Truck Bed Liner application.
            </h6>

            <div className="col-md-12 text-center">
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  As the pioneer of the sprayed on the truck bed liner industry
                  protecting millions of customer pickup trucks.
                </p>
              </div>
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Elegant Linings with Rhino sprayed-on truck bed liner is most
                  popular application with international brand wide range of
                  quality products for vehicles and automotive parts.
                </p>
              </div>
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Elegant Linings protective coating product application extend
                  vehicle life, save on maintenance, repair costs and down time.{" "}
                </p>
              </div>
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>The products and application opportunities are endless. </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5  pb-4">
        <div className="container p-5">
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-6">
              <div>
                <p style={{ lineHeight: "1.9em", fontSize: "0.9em" }}>
                  When you need protection without compromise, choose a
                  professionally applied Rhino Linings sprayed-on liner, only
                  offered by trained and authorized applicator Elegant Linings.
                  People have come to know and trust Rhino with Elegant Linings
                  quality, strength, and durability to protect their truck,
                  Jeep, trailer, boat, and ATV from abrasion, corrosion, and
                  daily wear and tear.
                </p>
              </div>
              <div>
                <p style={{ lineHeight: "1.9em", fontSize: "0.9em" }}>
                  Elegant Linings application with Rhino Linings protection
                  products are also used to protect trailers, boats, ATVs, RVs,
                  military vehicles, emergency vehicles, and commercial
                  equipment. Bed, bumper, fender, and grille to create a tough
                  factory-like finish. Standard black or custom colors (with
                  added UV protection) are available to keep your liner looking
                  just as vibrant and glossy as it did the day it was installed.
                </p>
              </div>
              <div>
                <p style={{ lineHeight: "1.9em", fontSize: "0.9em" }}>
                  Elegant Linings application is professionally installed and
                  designed to last a lifetime. Our watertight and impact
                  absorbent linings protect your ride from dings, scratches, and
                  scrapes.
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-lg-6">
              <div>
                <img src={Bedliners3} height={"100%"} width={"100%"} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="key-benefit bg-light pt-5 pb-5">
        <div className="container p-5 ">
          <div className="row">
            <h4
              className="pb-4"
              style={{
                fontWeight: "bolder",
                color: "",
                textTransform: "uppercase",
              }}
            >
              KEY BENEFITS{" "}
            </h4>
            <div className="col-md-6">
              <div
                className="row"
                style={{ lineHeight: "1.9em", fontSize: "0.9em" }}
              >
                <li>Long lasting protection</li>
                <li>Abrasion, corrosion, impact resistance</li>
                <li>More tolerant to substrate contamination</li>
                <li>Warranty</li>
                <li>Custom formulations</li>
                <li>Flexible and tenacious bond</li>
              </div>
            </div>
            <div className="col-md-6 ">
              <div
                className="row"
                style={{ lineHeight: "1.9em", fontSize: "0.9em" }}
              >
                <li>Full color range available with UV top coat</li>
                <li>Elasticized formulation</li>
                <li>Reduces noise from vibration</li>
                <li>Maintenance free liner</li>
                <li>Economical solution</li>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 pt-4 bg-light">
        <div className="container p-5">
          <div className="row">
            <h4 className="text-center pt-2 pb-2">Gallery</h4>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img src={Bedliners5} width={"100%"} height={"250rem"} alt="" />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img src={Bedliners6} width={"100%"} height={"250rem"} alt="" />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img src={Bedliners7} width={"100%"} height={"250rem"} alt="" />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img src={Bedliners8} width={"100%"} height={"250rem"} alt="" />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img src={Bedliners9} width={"100%"} height={"250rem"} alt="" />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={Bedliners10}
                  width={"100%"}
                  height={"250rem"}
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={Bedliners11}
                  width={"100%"}
                  height={"250rem"}
                  alt=""
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={Bedliners12}
                  width={"100%"}
                  height={"250rem"}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default BedlinersTransportation;
