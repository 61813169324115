import React from 'react'
import {TbMoodEmpty} from 'react-icons/tb'
import Layout from '../Components/Layout/Layout'
import { Link } from 'react-router-dom'
import '../Style/PageNotFound.css'


function PageNotFound() {
    return (
        <Layout>
            <section style={{ paddingTop: '80px' }}>
                <div className="container pt-5">
                    <div className="row">
                        <div className='pnf'>
                            <h1 className='pnf-title'>404</h1>
                            <TbMoodEmpty />
                            <h2 className='pnf-heading'>Oops ! Page Not Found</h2>
                            <Link to={"/"} className='pnf-btn' >Go Back</Link>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default PageNotFound