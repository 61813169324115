import './App.css';
import {Routes,Route} from 'react-router-dom';
import HomePage from './Pages/HomePage';
import AboutPage from './Pages/AboutPage';
import ProductPage from './Pages/ProductPage';
import ServicePage from './Pages/ServicePage';
import ContactPage from './Pages/ContactPage';
import PageNotFound from './Pages/PageNotFound';
import BedlinersTransportation from './Pages/BedlinersTransportation';
import FloorPage from './Pages/FloorPage';
import MilitaryPage from './Pages/MilitaryPage';
import MarinePage from './Pages/MarinePage';
import WasteWaterPage from './Pages/WasteWaterPage';
import BuildingPage from './Pages/BuildingPage';
import OilandGas from './Pages/OilandGas';
import Roofing from './Pages/Roofing';
import Polyurea from './Pages/ProductsPages/PolyureaHotSpray';
import PolyAsparticFlooringMaterials from './Pages/ProductsPages/PolyAsparticFlooringMaterials';
import Primer from './Pages/ProductsPages/Primer';
import LiquidMembrane from './Pages/ProductsPages/LiquidMembrane';
import Pigment from './Pages/ProductsPages/Pigment';
import AliphaticTopcoats from './Pages/ProductsPages/AliphaticTopcoats';
import WireTrimTapes from './Pages/ProductsPages/WireTrimTapes';
import EpoxycoatingandLinings from './Pages/ProductsPages/EpoxycoatingandLinings';
import PolyureaandFoamSprayEquipment from './Pages/ProductsPages/PolyureaandFoamSprayEquipment';
import SprayPolyurethaneFoam from './Pages/ProductsPages/SprayPolyurethaneFoam';




function App() {

  return (
    <>
    
      <Routes>
        
        <Route exact path='/' element={<HomePage/>}/>
         <Route path='/about' element={<AboutPage/>}/>
         <Route path='/product' element={<ProductPage/>} />
         <Route path="/service" element={<ServicePage/>}/>
         <Route path='/contact' element={<ContactPage/>} />
         <Route path='/*' element={<PageNotFound/>} />

         <Route path='/bedlinersandtransportation' element={<BedlinersTransportation/>} />
         <Route path='/industrialfloor' element={<FloorPage/>} />
         <Route path='/defenseandsecurity' element={<MilitaryPage/>} />
         <Route path='/marine' element={<MarinePage/>} />
         <Route path='/waterandwastewater' element={<WasteWaterPage/>} />
         <Route path='/buildingandconstructions' element={<BuildingPage/>} />
         <Route path='/oilandgas' element={<OilandGas/>} />
         <Route path='/roofing' element={<Roofing/>} />

         <Route path='product/polyurea' element={<Polyurea/>} />
         <Route path='product/spraypolyurethanefoam' element={<SprayPolyurethaneFoam/>} />
         <Route path='product/polyasparticfloors' element={<PolyAsparticFlooringMaterials/>} />
         <Route path='product/polyureaandfoamsprayequipment' element={<PolyureaandFoamSprayEquipment/>} />
         <Route path='product/primer' element={<Primer/>} />
         
         <Route path='product/liquidmembrane' element={<LiquidMembrane/>} />
         <Route path='product/pigment' element={<Pigment/>} />
         <Route path='product/aliphatictopcoats' element={<AliphaticTopcoats/>} />
         <Route path='product/wiretrimtape' element={<WireTrimTapes/>} />
         <Route path='product/epoxycoatingandlinings' element={<EpoxycoatingandLinings/>} />
      </Routes>
   
    </>
  );
  }

export default App;
