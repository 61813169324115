import React from "react";
import Layout from "../Components/Layout/Layout";
import { Link } from "react-router-dom";
import "../Style/ProductPage.css";

function ProductPage() {
  return (
    <Layout title={"Elegant Linings-Products"}>
      <section
        style={{ paddingTop: "7em", backgroundColor: "rgb(17, 64, 69)" }}
      >
        <div className="pt-5">
          <div className=" px-5 pb-2 pt-2">
            <h4
              style={{ fontWeight: "700", color: "white" }}
              className="pt-3 px-5"
            >
              Products
            </h4>
          </div>
        </div>
      </section>

      <section className="bg-light pb-5">
        <div className="container p-5  ">
          <div className="row">
            <div className="row row-cols-1 row-cols-lg-2 g-4">
              <div className="col-12 ">
                <div className="card ">
                  <div className="card-body">
                    <h5 className="card-title pt-4 text-center pb-5">
                      Polyurea Hot Spray
                    </h5>
                    <p className="card-text text-center pb-2">
                      <Link
                        to={"/product/polyurea"}
                        style={{ textDecoration: "none", color: "gray" }}
                      >
                        show product
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title pt-4 text-center pb-5">
                      Spray Polyurethane Foams
                    </h5>
                    <p className="card-text text-center pb-2">
                      <Link
                        to={"/product/spraypolyurethanefoam"}
                        style={{ textDecoration: "none", color: "gray" }}
                      >
                        show product
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title pt-4 text-center pb-5">
                      Epoxy coating and Linings
                    </h5>
                    <p className="card-text text-center pb-2">
                      <Link
                        to={"/product/EpoxycoatingandLinings"}
                        style={{ textDecoration: "none", color: "gray" }}
                      >
                        show product
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title pt-4 text-center pb-5">
                      Polyaspartic Floors
                    </h5>
                    <p className="card-text text-center pb-2">
                      <Link
                        to={"/product/polyasparticfloors"}
                        style={{ textDecoration: "none", color: "gray" }}
                      >
                        show product
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title pt-4 text-center pb-5">
                      Liquid Membrane
                    </h5>
                    <p className="card-text text-center pb-2">
                      <Link
                        to={"/product/liquidmembrane"}
                        style={{ textDecoration: "none", color: "gray" }}
                      >
                        show product
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title pt-4 text-center pb-5">
                      Primers
                    </h5>
                    <p className="card-text text-center pb-2">
                      <Link
                        to={"/product/primer"}
                        style={{ textDecoration: "none", color: "gray" }}
                      >
                        show product
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title pt-4 text-center pb-5">
                      Aliphatic Top coats
                    </h5>
                    <p className="card-text text-center pb-2">
                      <Link
                        to={"/product/aliphatictopcoats"}
                        style={{ textDecoration: "none", color: "gray" }}
                      >
                        show product
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title pt-4 text-center pb-5">
                      Pigment
                    </h5>
                    <p className="card-text text-center pb-2">
                      <Link
                        to={"/product/pigment"}
                        style={{ textDecoration: "none", color: "gray" }}
                      >
                        show product
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title pt-4 text-center pb-5">
                      Polyurea and Foam Spray Equipment
                    </h5>
                    <p className="card-text text-center pb-2">
                      <Link
                        to={"/product/polyureaandfoamsprayequipment"}
                        style={{ textDecoration: "none", color: "gray" }}
                      >
                        show product
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 ">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title pt-4 text-center pb-5">
                      Wire Trim Tape
                    </h5>
                    <p className="card-text text-center pb-2">
                      <Link
                        to={"/product/wiretrimtape"}
                        style={{ textDecoration: "none", color: "gray" }}
                      >
                        show product
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ProductPage;
