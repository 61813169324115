import React from "react";
import Layout from "../Components/Layout/Layout";
import "../Style/MarinePage.css";
import marineImg3 from "../Assets/marine3.jpg";
import BannerWorkImg from "../Assets/BannerWork.JPG";
import marineImg6 from "../Assets/marine6.jpg";
import marineImg8 from "../Assets/marine8.jpg";
import marineImg9 from "../Assets/marine9.jpg";
import marineImg10 from "../Assets/marine10.jpg";
import marineImg11 from "../Assets/marine11.jpg";
import marineImg12 from "../Assets/marine12.jpeg";
import marineImg13 from "../Assets/marine13.jpg";
import marineImg14 from "../Assets/marine14.jpg";
import marineImg15 from "../Assets/marine15.jpg";
import marineImg2 from "../Assets/marine2.jpg";
import marineImg4 from "../Assets/marine4.jpg";

function MarinePage() {
  return (
    <Layout title={"Elegant Linings-Marine"}>
      <section style={{ paddingTop: "7rem" }}>
        <div className="pt-5 " style={{ backgroundColor: "gray" }}>
          <div className=" px-5 pb-2  ">
            <h4
              style={{ fontWeight: "700", color: "white" }}
              className="pt-3 px-5 "
            >
              Marine
            </h4>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5 image-marine" style={{ minHeight: "65vh" }}>
        <div className="container pt-5 p-5">
          <div className="row">
            <h6
              className="pb-4 text-center"
              style={{ lineHeight: "1.9em", letterSpacing: "0.3px" }}
            >
              Elegant Linings always work with shipping and marine industry,
              Polyurea coatings can be very effective in protecting all
              substrates (steel, aluminum, geotextile, concrete and fiberglass.
              Polyurea can be applied to increase the longevity and reduce the
              maintenance required of decks, hulls, ballast tanks, filling
              floats, freezing and cooling rooms, water tanks, holding tanks,
              fish hold liners, floating docks, crane, navigation equipment’s
              etc.{" "}
            </h6>
          </div>
        </div>
      </section>
      <section className=" pt-5 pb-5">
        <div className="container p-5 ">
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-6">
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  If you’re looking for an exceptionally long-lasting waterproof
                  and protective solution, then polyurea coatings are the right
                  choice. These ultra-high-strength, elastomeric coatings
                  outperform other coatings that have been traditionally used
                  such as polyurethanes, epoxies, and other hybrid products.
                  Here are a few reasons why polyurea is so widely used in the
                  marine industry.{" "}
                </p>
              </div>
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Polyurea can be used to protect the hull of the boat where
                  these items are stored from exposure to external elements and
                  water leaks. It will also protect the containment area from
                  abrasive goods such as aggregates. Polyurea makes it easier to
                  keep the hulls clean and free of contaminants that can spoil
                  goods during the transportation process. polyureas provide a
                  chemical-resistant lining for storage tanks, pipes, and cargo
                  areas. Polyurea coatings will lasting for years without
                  failure, preventing rust, heat, cracking and deteriorations{" "}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-lg-6">
              <div>
                <img
                  src={marineImg3}
                  width={"100%"}
                  height={"100%"}
                  alt="marineImg3"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5 bg-light">
        <div className="container p-5 text-center">
          <div className="row">
            <div className="col-md-12">
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Polyurea coatings are ideal for marine applications for many
                  reasons, one of them being the need for fast job turnaround in
                  dry dock to keep repairs and upgrades on schedule. Polyurea’s
                  quick cure times play a big role here and polyurea will hold
                  up where other systems will fail. Polyurea also are an
                  excellent choice when fast curing applications are applied in
                  high humidity or low temperature
                </p>
              </div>
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  UV rays can damage of water vessels exterior surface and
                  aliphatic versions of polyurea coatings are UV resistant and
                  provide a cost-effective solution to preventing UV damages
                </p>
              </div>
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Polyurea protective coatings for boats and ships can help keep
                  maintenance requirements to a minimum by limiting direct
                  exposure to damaging elements.
                </p>
              </div>
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Proper maintenance and polyurea applications can help maintain
                  and extend the lifespan of a marine vessel.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="background-image-marine pt-5 pb-5">
        <div className="container p-5">
          <div className="row">
            <div className="pt-5 pb-5 text-start">
              <div
                className="pt-4"
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  polyurea is completely eco-friendly. Designed with marine life
                  and other environmental elements in mind, polyurea is a
                  sustainable coating that doesn’t release harmful gasses such
                  as CO2 into the environment during its production.
                </p>
              </div>
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Elegant Linings Polyurea and quality applications provides
                  excellent results and is ideal for the following applications:
                </p>
              </div>
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Polyurea protection for vessel hulls and decks, Anchor
                  Ropes,cargo loading cranes and areas, car decks on passenger
                  ferries, fishing boats, Sail Boat, Corrosion for all vessel
                  components, buoys, quays and other waterline elements, salt
                  water tanks on fishing vessels, oil tank, water sports, Ski
                  jet materials and equipment, Personal/Commercial water crafts
                  for Shipping Containers
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light pt-5 pb-5">
        <div className="container p-5 ">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <h4 className="text-center pb-2">Gallery</h4>
                <div className="col-12 col-sm-6 col-lg-3 pt-4">
                  <div className="">
                    <img
                      src={BannerWorkImg}
                      alt="BannerWorkImg"
                      width={"100%"}
                      height={"250px"}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 pt-4">
                  <div className="">
                    <img
                      src={marineImg6}
                      alt="marineImg6"
                      width={"100%"}
                      height={"250px"}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 pt-4">
                  <div className="">
                    <img
                      src={marineImg8}
                      alt="marineImg8"
                      width={"100%"}
                      height={"250px"}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 pt-4">
                  <div className="">
                    <img
                      src={marineImg9}
                      alt="marineImg9"
                      width={"100%"}
                      height={"250px"}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 pt-4">
                  <div className="">
                    <img
                      src={marineImg10}
                      alt="marineImg10"
                      width={"100%"}
                      height={"250px"}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 pt-4">
                  <div className="">
                    <img
                      src={marineImg11}
                      alt="marineImg11"
                      width={"100%"}
                      height={"250px"}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 pt-4">
                  <div className="">
                    <img
                      src={marineImg12}
                      alt="marineImg12"
                      width={"100%"}
                      height={"250px"}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 pt-4">
                  <div className="">
                    <img
                      src={marineImg13}
                      alt="marineImg13"
                      width={"100%"}
                      height={"250px"}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 pt-4">
                  <div className="">
                    <img
                      src={marineImg14}
                      alt="marineImg14"
                      width={"100%"}
                      height={"250px"}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 pt-4 ">
                  <div className="">
                    <img
                      src={marineImg15}
                      alt="marineImg15"
                      width={"100%"}
                      height={"250px"}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 pt-4 ">
                  <div className="">
                    <img
                      src={marineImg2}
                      alt="marineImg2"
                      width={"100%"}
                      height={"250px"}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-lg-3 pt-4 ">
                  <div className="">
                    <img
                      src={marineImg4}
                      alt="marineImg4"
                      width={"100%"}
                      height={"250px"}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default MarinePage;
