import React from 'react'
import {Helmet} from 'react-helmet'

import Footer from './Footer'
import Header from './Header'
import { Toaster } from 'react-hot-toast'


function Layout({children,description,keywords,title,author}) {
  return (
    <div>
 <Helmet>
  <meta charSet='utf-8'/>
  <meta name='description' content={description}/>
  <meta name='keywords' content={keywords} />
  <meta name='author' content={author} />
  <title>{title}</title>
 </Helmet>

           <Header />
           <main  style={{minHeight:'95vh'}}>
            {window.scrollTo(0,0)}
            
           {children}
           <Toaster/>
           </main>

           <Footer/>
    </div>
  )
}

export default Layout