import React from "react";
import Layout from "../Components/Layout/Layout";
import "../Style/FloorPage.css";
import industrialfloorImg2 from "../Assets/industrialfloor2.jpg";
import serviceImg8 from "../Assets/service8.jpg";
import industrialfloorImg3 from "../Assets/industrialfloor3.jpg";
import parkingImg from "../Assets/parking.jpg";
import parkingImg1 from "../Assets/parking1.jpg";
import parkingImg2 from "../Assets/parking2.jpg";
import parkingImg3 from "../Assets/parking3.jpg";
import industrialfloorImg8 from "../Assets/industrialfloor8.jpg";
import industrialfloorImg4 from "../Assets/industrialfloor4.jpg";
import industrialfloorImg5 from "../Assets/industrialfloor5.jpg";

function FloorPage() {
  return (
    <Layout title={"Elegant Linings-Industrial Flooring"}>
      <section style={{ paddingTop: "7rem" }}>
        <div className="pt-5 " style={{ backgroundColor: "gray" }}>
          <div className=" px-5 pb-2  ">
            <h4
              style={{ fontWeight: "700", color: "white" }}
              className="pt-3 px-5 "
            >
              Industrial Floor
            </h4>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-5 image-floor" style={{ minHeight: "65vh" }}>
        <div className="container pt-5 p-5">
          <div className="row">
            <h5
              className="pb-4 text-center"
              style={{ lineHeight: "1.9em", letterSpacing: "0.3px" }}
            >
              Food, Beverage and Food Service Flooring
            </h5>

            <div className="col-md-12 text-center">
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <h6>Meat, Fish, Poultry, Cold Room Facility</h6>
              </div>
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Amongst the most extreme flooring environments, the food and
                  beverage industry demands the best flooring solutions. From
                  impact, abrasion, chemical and thermal resistance Rhino
                  Flooring delivers outstanding, fast turn-around flooring
                  systems for the food and beverage industry that are time
                  proven.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-5 ">
        <div className="container p-5">
          <div className="row">
            <h5 className="pb-5 text-center" style={{ fontWeight: "900" }}>
              Hotel, Restaurants, Schools, Hospitals
            </h5>
            <div className=" col-12 col-sm-12 col-lg-6">
              <p
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                Commercial kitchens, restaurants, bars, and food storage areas
                are high priority areas for quality, safe and hygienic flooring
                systems. They must consistently meet the highest standards of
                sanitation, with enhanced antimicrobial to prevent food
                contamination. These areas a critical when it comes to finding
                the perfect balance between anti-slip and clean ability, both
                properties being essential. Hard-wearing, UV-stable, stain
                resistant with the ability to also.
              </p>
            </div>
            <div className=" col-12 col-sm-12 col-lg-6">
              <div>
                <img
                  src={industrialfloorImg2}
                  width={"100%"}
                  height={"100%"}
                  alt="industrialfloorImg2"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light  pt-5 pb-5 ">
        <div className="container p-5 text-center">
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-12">
              <h5 className="pb-4" style={{ fontWeight: "bolder" }}>
                Production and Institutional Flooring{" "}
              </h5>
              <hr className="pb-3" />
              <p
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                Manufacturing Bay, Hanger, Warehouse, Vehicle Parking and
                Garages Floor, Shops Floor
              </p>

              <div className="">
                <p
                  style={{
                    fontSize: "0.9em",
                    lineHeight: "1.9em",
                    letterSpacing: "0.3px",
                  }}
                >
                  Manufacturing environments are demanding yet often cover vast
                  areas. These facilities require durable, hard-wearing
                  industrial coatings that with withstand the abuse of
                  fork-lifts, tow motors, hard wheeled cars, as well as oil,
                  grease, and chemical exposure. Slip resistance requirements
                  combined with clean ability all play an important factor in
                  choosing the right floor, not forgetting that color choice can
                  have a significant impact on energy savings, and even moral.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" UniversityMedicalLaboratories pt-5 pb-5">
        <div className="container p-5 ">
          <div className="row">
            <div className="col-md-12">
              <h5 style={{ fontWeight: "900" }} className="pt-4 pb-3">
                University, Schools, Hospitals, Medical Laboratories.
              </h5>
              <p
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                Prisons, hospitals, laboratories, research and educational
                facilities all have their unique and demanding <br />{" "}
                requirements that in many cases, only seamless polymer flooring
                can meet. Fast turnaround, chemical <br /> and stain resistance,
                anti-microbial, seamless, UV stability, thermal shock resistant
                with a lifespan of <br /> decades are just some of the features
                we can offer.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5  pb-5">
        <div className="container  p-5">
          <div className="row">
            <h5 className="pb-2" style={{ fontWeight: "bolder" }}>
              Parking Decks
            </h5>
            <div className="col-12 col-md-4">
              <div className="pt-2">
                <img
                  src={serviceImg8}
                  width={"100%"}
                  height={"250em"}
                  alt="serviceImg8"
                />
              </div>
            </div>
            <div className="col-12 col-md-8">
              <h6 className="pt-3" style={{ fontWeight: "bolder" }}>
                Vehicle Parking Bay Coating
              </h6>
              <p
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                Polyurethane floor coatings are an ideal option for parking
                structures and commercial garage flooring. By helping preserve
                the underlying concrete, polyurethane flooring can also help
                enhance safety and extend the lifespan of a concrete garage
                floor. With the constant weight of heavy vehicles and moving
                traffic, parking garage flooring must be incredibly strong,
                durable, and properly installed to avoid deterioration that
                would necessitate a temporary closure of the facility.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 pt-4 bg-light">
        <div className="container p-5">
          <div className="row">
            <h4 className="text-center pt-2 pb-2">Gallery</h4>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={industrialfloorImg3}
                  width={"100%"}
                  height={"250rem"}
                  alt="industrialfloorImg3"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={parkingImg}
                  width={"100%"}
                  height={"250rem"}
                  alt="parkingImg"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={parkingImg1}
                  width={"100%"}
                  height={"250rem"}
                  alt="parkingImg1"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={parkingImg2}
                  width={"100%"}
                  height={"250rem"}
                  alt="parkingImg2"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={parkingImg3}
                  width={"100%"}
                  height={"250rem"}
                  alt="parkingImg3"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={industrialfloorImg8}
                  width={"100%"}
                  height={"250rem"}
                  alt="industrialfloorImg8"
                />
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={industrialfloorImg4}
                  width={"100%"}
                  height={"250rem"}
                  alt="industrialfloorImg4"
                />
              </div>
            </div>

            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={industrialfloorImg5}
                  width={"100%"}
                  height={"250rem"}
                  alt="industrialfloorImg5"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default FloorPage;
