import React from "react";
import Layout from "../Components/Layout/Layout";
import "../Style/MilitaryPage.css";
import militaryImg1 from "../Assets/military1.jpg";
import militaryImg2 from "../Assets/military2.jpg";
import militaryImg3 from "../Assets/military3.jpg";
import militaryImg4 from "../Assets/military4.jpg";
import militaryImg5 from "../Assets/military5.jpg";
import militaryImg6 from "../Assets/military6.jpg";

function MilitaryPage() {
  return (
    <Layout title={"Elegant Linings-Military"}>
      <section style={{ paddingTop: "7rem" }}>
        <div className="pt-5 " style={{ backgroundColor: "gray" }}>
          <div className=" px-5 pb-2  ">
            <h4
              style={{ fontWeight: "700", color: "white" }}
              className="pt-3 px-5 "
            >
              Military
            </h4>
          </div>
        </div>
      </section>
      <section
        className="pt-5 pb-5 image-military"
        style={{ minHeight: "65vh" }}
      >
        <div className="container  p-5">
          <div className="row">
            <h6
              className="pt-4 pb-4 text-center"
              style={{ lineHeight: "1.9em", letterSpacing: "0.3px" }}
            >
              Elegant Linings’ protective coatings applications are used by all
              branches of the military, and using Rhino Linings world class
              products are CARC compatible and proven to withstand most
              conventional weapon and explosive threat conditions, keeping live
              and assets safe.
            </h6>

            <div className="col-md-12 text-center">
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Polyurea protective coatings products of aromatic and
                  aliphatic that are suitable for very specific defense and
                  security applications and conditions for the defense and
                  security industry are a paramount asset that offers
                  unparalleled blast protection for military personnel and
                  civilians in active combat zones
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5 pt-5">
        <div className="container p-5 ">
          <div className="row">
            <div className="col-12 col-md-6 pt-4">
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Polyurea protective coatings provide unparalleled structural
                  stability combined with superior impact resistance and
                  incredible flexibility to minimize infrastructural damages and
                  injuries caused by air strikes, blasts, and ballistic arms
                  fire in active war zones.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 pt-4">
              <div className="">
                <img
                  src={militaryImg1}
                  width={"100%"}
                  height={"100%"}
                  alt="militaryImg1"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-light pt-5 pb-5">
        <div className="container p-5">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className=" ">
                <ul style={{ lineHeight: "1.9em", fontSize: "0.9em" }}>
                  <li>
                    Material deflects energy of blasts while keeping structures
                    intact
                  </li>
                  <li>Sprayed lining creates a monolithic, seamless barrier</li>
                  <li>
                    High flexibility of material eliminates or reduces shrapnel
                    and other collateral damage
                  </li>
                  <li>
                    Adheres to a variety of surfaces, including concrete, steel,
                    aluminum, fiberglass and more
                  </li>
                  <li>
                    Adjustable thickness, depending on level of protection
                    required
                  </li>
                  <li>Excellent Water proofing</li>
                  <li>Corrosion resistance</li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-6 ">
              <div className="">
                <ul style={{ lineHeight: "1.9em", fontSize: "0.9em" }}>
                  <li>Abrasion resistance</li>
                  <li>Impact resistance</li>
                  <li>UV ray resistance (aliphatic versions)</li>
                  <li>Shock and vibration absorption</li>
                  <li>High Tensile Strength </li>
                  <li>Excellent bonding with all substrate</li>
                  <li>Curing within seconds</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className=" boat-section pt-5 pb-5">
        <div className="container p-5">
          <div className="row">
            <div className="pt-5 pb-5 text-center">
              <div className="" style={{ paddingTop: "80px" }}>
                <p style={{ lineHeight: "1.9em", fontSize: "0.9em" }}>
                  Polyurea coatings are an excellent solution to add a shield of
                  protection <br /> and it effectively stops a wide range of
                  ballistic threats to a wide variety of vehicles, artillery,
                  boats and structures.{" "}
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <ul style={{ lineHeight: "1.9em", fontSize: "0.9em" }}>
                <li>Tanks and other defense vehicles</li>
                <li>Composite and body armor</li>
                <li>Fuel trucks</li>
                <li>Storage tanks</li>
                <li>Buildings and structural walls</li>
                <li>Explosive detonation chambers</li>
                <li>Bases, Storage Bunkers, Checkpoints</li>
                <li>High traffic ramps and walkways</li>
                <li>Spall protection</li>
                <li>Coast Guard boats.</li>
              </ul>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 pt-4 ">
        <div className="container p-5">
          <div className="row">
            <h4 className="text-center pt-2 pb-2">Gallery</h4>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={militaryImg2}
                  width={"100%"}
                  height={"250rem"}
                  alt="militaryImg2"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={militaryImg3}
                  width={"100%"}
                  height={"250rem"}
                  alt="militaryImg3"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={militaryImg4}
                  width={"100%"}
                  height={"250rem"}
                  alt="militaryImg4"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={militaryImg5}
                  width={"100%"}
                  height={"250rem"}
                  alt="militaryImg5"
                />
              </div>
            </div>
            <div className="col-12 d-flex justify-content-center pt-4">
              <div className="">
                <img
                  src={militaryImg6}
                  width={"100%"}
                  height={"250rem"}
                  alt="militaryImg6"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default MilitaryPage;
