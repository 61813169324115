import React from 'react'
import Layout from '../../Components/Layout/Layout'
import { Link } from 'react-router-dom'
function LiquidMembrane() {
    return (
        <Layout >

            <section >
              

                    <div className="container-fluid pt-5 pb-5">
                        <div className="row">
                            <div className="col-12 col-sm-12 col-lg-12">
                                <h5 className='text-center' style={{ paddingTop: '13em' }}>Coming Soon!</h5>
                                <p className='pt-2 text-center'>We are currently working on this feature and will launch soon!</p>
                                
                                <div className='d-flex justify-content-center pt-2'>
                                   <Link to={'/product'} className='btn' >Back </Link>
                                </div>

                            </div>
                        </div>
                    </div>
                
            </section>






        </Layout>
    )
}

export default LiquidMembrane