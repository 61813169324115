import React from "react";
import Layout from "../Components/Layout/Layout";
import { Link } from "react-router-dom";
import "../Style/HomePage.css";
import BannerImg5 from "../Assets/Banner5.jpg";
import BannerImg1 from "../Assets/Banner1.jpg";
import BannerImg2 from "../Assets/Banner2.jpg";
import BannerImg4 from "../Assets/Banner4.jpg";
import BoatBannerImg from "../Assets/BoatBanner.jpg";
import GodownImg from "../Assets/BlueBannerGodown.jpg";
import BannerImg3 from "../Assets/Banner3.jpg";
import BannerImg6 from "../Assets/Banner6.jpg";
import SprayImg from "../Assets/welcomeimage.jpg";
import BedlinersCardImg from "../Assets/BedlinersCard.jpg";
import IndustrialFloorCardImg from "../Assets/IndustrialFlooringCard1.jpg";
import MiltaryCardImg from "../Assets/MiltaryCard.jpg";
import MarineCardImg from "../Assets/MarineCard.jpg";
import WaterandwastewaterCardImg from "../Assets/WaterandwastewaterCard.jpg";
import BuildingandConstructionCardImg from "../Assets/Building&ConstructionCard.jpg";
import OilandGasCardImg from "../Assets/OilandGasCard.jpg";
import RoofingCardImg from "../Assets/RoofingCard.jpg";

function HomePage() {
  return (
    <Layout title={"Elegant Linings-Home"}>
      <section style={{ paddingTop: "6rem" }}>
        <div
          id="carouselExampleAutoplaying"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active ">
              <img
                src={BannerImg5}
                className="d-block0"
                style={{ height: "590px", width: "100%" }}
                alt="BannerImg5"
              />
            </div>

            <div className="carousel-item">
              <img
                src={BannerImg1}
                className="d-block  "
                style={{ height: "590px", width: "100%" }}
                alt="BannerImg1"
              />
            </div>
            <div className="carousel-item">
              <img
                src={BannerImg2}
                className="d-block  "
                style={{ height: "590px", width: "100%" }}
                alt="BannerImg2"
              />
            </div>
            <div className="carousel-item">
              <img
                src={BannerImg4}
                className="d-block 0"
                style={{ height: "590px", width: "100%" }}
                alt="BannerImg4"
              />
            </div>

            <div className="carousel-item">
              <img
                src={BoatBannerImg}
                className="d-block  "
                style={{ height: "590px", width: "100%" }}
                alt="BoatBannerImg"
              />
            </div>
            <div className="carousel-item">
              <img
                src={GodownImg}
                className="d-block  "
                style={{ height: "590px", width: "100%" }}
                alt="GodownImg"
              />
            </div>
            <div className="carousel-item">
              <img
                src={BannerImg3}
                className="d-block  "
                style={{ height: "590px", width: "100%" }}
                alt="BannerImg3"
              />
            </div>
            <div className="carousel-item">
              <img
                src={BannerImg6}
                className="d-block  "
                style={{ height: "590px", width: "100%" }}
                alt="BannerImg6"
              />
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleAutoplaying"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </section>

      <section className="welcome-section pt-5 pb-5 text-white">
        <div className="container ">
          <p
            className="text-center pt-5 "
            style={{
              fontSize: "1.4rem",
              fontWeight: "700",
              letterSpacing: "0.1rem",
            }}
          >
            Welcome to Elegant Linings
          </p>
          <div className="tech-conatiner ">
            <div className="separator-homepage">
              <div className="line-homepage"></div>
            </div>
          </div>
          <div className="row ">
            <div className="col-12 col-sm-12 col-lg-4 ">
              <div className="pt-4">
                <img
                  src={SprayImg}
                  width={"100%"}
                  style={{ borderRadius: "5px", maxHeight: "34rem" }}
                  alt="SprayImg"
                />
              </div>
            </div>
            <div
              className="col-12 col-sm-12 p-5 col-lg-8 pt-5"
              style={{ lineHeight: "28px" }}
            >
              <p style={{ fontSize: "0.85em", letterSpacing: "0.1rem" }}>
                We, Elegant Linings Technical Services LLC, The Company located
                in the Ras Al Khor industrial area 2, Dubai, United Arab
                Emirates.
              </p>
              <p style={{ fontSize: "0.85em", letterSpacing: "0.1rem" }}>
                Elegant Linings is one of the leading supplier and applicator,
                specialized in Polyurea protective coating and linings, PU foam
                Insulation, Epoxy, Polyaspartic floor coating systems. Our team
                provides total quality management system and service and highly
                professional, technically qualified more than 20 year experience
                in the industry of Automotive, Building and Construction, Oil
                and Gas, Marine, Agriculture, Defense and Security, Marine in
                the middle east, offers year's round 24/7 advanced technical
                support and training backed by dedicated superior technical
                service staff with years of experience.
              </p>
              <p style={{ fontSize: "0.85em", letterSpacing: "0.1rem" }}>
                PU foam, Floor coatings, Topcoat, Primer, Wire Trim Tapes, Spray
                Machinery, Accessories and Spare parts Our supplier provides
                Superior quality products for industrial, residential,
                commercial, purpose. All products are tested and certified by
                authorities
              </p>
            </div>
          </div>
        </div>
      </section>

      <section className="p-5 pt-5 pb-5">
        <div className="container  pb-5">
          <div className="row">
            <h5 className="pt-2 text-center " style={{ fontWeight: "900" }}>
              INDUSTRIES
            </h5>
            <div className=" col-12 col-sm-6  col-lg-3 pt-5   d-flex justify-content-center">
              <div className="card-container ">
                <Link to={"/bedlinersandtransportation"} className="card-first">
                  <div className="front-content">
                    <img
                      src={BedlinersCardImg}
                      width={"100%"}
                      height={"100%"}
                      alt="BedlinersCardImg"
                    />
                  </div>
                  <div className="content">
                    <p className="heading ">
                      BEDLINERS
                      <br />&<br />
                      TRANSPORTATION
                    </p>
                    <p>
                      <button className="btn btn-danger">Click Here</button>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="  col-12 col-sm-6  col-lg-3 pt-5 d-flex justify-content-center  ">
              <div className="card-container">
                <Link to={"/industrialfloor"} className="card-first">
                  <div className="front-content">
                    <img
                      src={IndustrialFloorCardImg}
                      width={"100%"}
                      height={"100%"}
                      alt="IndustrialFloorCardImg"
                    />
                  </div>
                  <div className="content">
                    <p className="heading ">INDUSTRIAL FLOORING</p>
                    <p>
                      <button className="btn btn-danger">Click Here</button>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className=" col-12 col-sm-6  col-lg-3 pt-5 d-flex justify-content-center  ">
              <div className="card-container">
                <Link to={"/defenseandsecurity"} className="card-first">
                  <div className="front-content">
                    <img
                      src={MiltaryCardImg}
                      width={"100%"}
                      height={"100%"}
                      alt="MiltaryCardImg"
                    />
                  </div>
                  <div className="content">
                    <p className="heading ">
                      DEFENSE
                      <br />&<br />
                      SECURITY
                    </p>
                    <p>
                      <button className="btn btn-danger">Click Here</button>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="  col-12 col-sm-6  col-lg-3 pt-5 d-flex justify-content-center  ">
              <div className="card-container">
                <Link to={"/marine"} className="card-first">
                  <div className="front-content">
                    <img
                      src={MarineCardImg}
                      width={"100%"}
                      height={"100%"}
                      alt="MarineCardImg"
                    />
                  </div>
                  <div className="content">
                    <p className="heading ">MARINE</p>
                    <p>
                      <button className="btn btn-danger">Click Here</button>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="  col-12 col-sm-6  col-lg-3 pt-5 d-flex justify-content-center  ">
              <div className="card-container">
                <Link to="/waterandwastewater" className="card-first">
                  <div className="front-content">
                    <img
                      src={WaterandwastewaterCardImg}
                      width={"100%"}
                      height={"100%"}
                      alt="WaterandwastewaterCardImg"
                    />
                  </div>
                  <div className="content">
                    <p className="heading ">
                      WATER
                      <br />&<br />
                      WASTE WATER
                    </p>
                    <p>
                      <button className="btn btn-danger">Click Here</button>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="  col-12 col-sm-6  col-lg-3 pt-5 d-flex justify-content-center  ">
              <div className="card-container">
                <Link to={"/buildingandconstructions"} className="card-first">
                  <div className="front-content">
                    <img
                      src={BuildingandConstructionCardImg}
                      width={"100%"}
                      height={"100%"}
                      alt="BuildingandConstructionCardImg"
                    />
                  </div>
                  <div className="content">
                    <p className="heading ">
                      BUILDING
                      <br />&<br />
                      CONSTRUCTION
                    </p>
                    <p>
                      <button className="btn btn-danger">Click Here</button>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="  col-12 col-sm-6  col-lg-3 pt-5 d-flex justify-content-center">
              <div className="card-container">
                <Link to={"/oilandgas"} className="card-first">
                  <div className="front-content">
                    <img
                      src={OilandGasCardImg}
                      width={"100%"}
                      height={"100%"}
                      alt="OilandGasCardImg"
                    />
                  </div>
                  <div className="content">
                    <p className="heading ">
                      OIL
                      <br />& <br />
                      GAS
                    </p>
                    <p>
                      <button className="btn btn-danger">Click Here</button>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
            <div className="  col-12 col-sm-6  col-lg-3 pt-5 d-flex justify-content-center  ">
              <div className="card-container">
                <Link to={"/roofing"} className="card-first">
                  <div className="front-content">
                    <img
                      src={RoofingCardImg}
                      width={"100%"}
                      height={"100%"}
                      alt="RoofingCardImg"
                    />
                  </div>
                  <div className="content">
                    <p className="heading ">ROOFING</p>
                    <p>
                      <button className="btn btn-danger">Click Here</button>
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default HomePage;
