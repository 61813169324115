import React, { useMemo, useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import Layout from "../Components/Layout/Layout";
import toast from "react-hot-toast";
import countryList from "react-select-country-list";
import Select from "react-select";
import "../Style/ContactPage.css";

function ContactPage() {
  const [value, setValue] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const changeHandler = (value) => {
    setValue(value);
  };

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_0ob1d5b",
        "template_f9go3xp",
        form.current,
        "nsidWHJS2OgzNT5LJ"
      )
      .then(
        (result) => {
          console.log(result.text);
          toast.success("Message Send Successfully");
        },
        (error) => {
          console.log(error.text);
          toast.error("Something Went wrong ; Message Not Send");
        }
      );
  };

  return (
    <Layout title={"Elegant Linings-Contact Us"}>
      <section style={{ paddingTop: "7em" }}>
        <div className="pt-5 " style={{ backgroundColor: "rgb(17, 64, 69)" }}>
          <div className=" px-5 pb-2 pt-2  ">
            <h4
              style={{ fontWeight: "700", color: "white" }}
              className="pt-3 px-5"
            >
              Contact Us
            </h4>
          </div>
        </div>
      </section>
      <section
        className="pt-5 pb-5 bg-light "
        style={{ backgroundColor: "#ededed" }}
      >
        <div className="ContactForm">
          <div className="container">
            <div className="row">
              <div className="col-12 col-sm-12 col-lg-6 ">
                <div className="contactForm">
                  <p style={{ color: "gray" }}>
                    Fields marked with an{" "}
                    <span style={{ color: "#cf0c0c", fontSize: "1.1rem" }}>
                      {" "}
                      *{" "}
                    </span>
                    are required
                  </p>
                  <form ref={form} onSubmit={sendEmail}>
                    <label style={{ fontWeight: "900" }}>
                      Business Unit{" "}
                      <span style={{ color: "#cf0c0c", fontSize: "1.1rem" }}>
                        *
                      </span>
                    </label>

                    <input
                      type="text"
                      className="form-control w-100  mt-2 mb-2"
                      name="user_unit"
                      required
                    />

                    <label style={{ fontWeight: "900" }}>
                      Name/Company
                      <span style={{ color: "#cf0c0c", fontSize: "1.1rem" }}>
                        {" "}
                        *{" "}
                      </span>
                    </label>
                    <input
                      type="text"
                      className="form-control w-100  mt-2 mb-2"
                      name="user_name"
                      required
                    />

                    <label style={{ fontWeight: "900" }}>
                      Email{" "}
                      <span style={{ color: "#cf0c0c", fontSize: "1.1rem" }}>
                        {" "}
                        *{" "}
                      </span>
                    </label>
                    <input
                      type="email"
                      className="form-control w-100 mt-2 mb-2"
                      name="user_email"
                      required
                    />

                    <label style={{ fontWeight: "900" }}>
                      Phone{" "}
                      <span style={{ color: "#cf0c0c", fontSize: "1.1rem" }}>
                        {" "}
                        *{" "}
                      </span>
                    </label>
                    <input
                      type="tel"
                      className="form-control w-100 mt-2 mb-2"
                      name="user_phone"
                      required
                    />

                    <label style={{ fontWeight: "900" }}>
                      Country{" "}
                      <span style={{ color: "#cf0c0c", fontSize: "1.1rem" }}>
                        {" "}
                        *{" "}
                      </span>
                    </label>

                    <Select
                      className="mb-2 mt-2"
                      options={options}
                      value={value}
                      onChange={changeHandler}
                      name="user_country"
                      required
                    />

                    <label style={{ fontWeight: "900" }}>Message</label>
                    <textarea
                      className="form-control w-100 mt-2 mb-2"
                      rows={"5"}
                      cols={"33"}
                      placeholder="Write something..."
                      name="message"
                      required
                    />
                    <input
                      className="btn mt-3"
                      style={{
                        backgroundColor: "darkgreen",
                        color: "whitesmoke",
                        fontWeight: "900",
                      }}
                      type="submit"
                      value="Send"
                    />
                  </form>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-lg-6">
                <div className="mx-5 pt-5">
                  <h6>Elegant Linings Technical Services L.L.C</h6>
                  <h6 className="pt-4" style={{ lineHeight: "1.9rem" }}>
                  WH#16, Al Arif Compound, Street 7 <br />
                  Ras Al Khor Industrial Area 2<br />

                    Dubai,UAE (United Arab Emirates)<br />
                    
                  </h6>
                  <div className="pt-4">
                    <h6 style={{ lineHeight: "1.9rem" }}>
                      Phone:{" "}
                      <span style={{ color: "gray" }}>(+971) 4 547 4832</span>{" "}
                      <br />
                    
                      <br />
                      Website: https://www.elegantlinings.com
                    </h6>

                    <p className="pt-3">
                      Visit our Global Directory to find contact information for
                      Elegant Linings retail and/or industrial locations in your
                      country.
                    </p>

                    <h6 className="pt-3" style={{ lineHeight: "1.9rem" }}>
                      Working Time <br />
                      Mon – Sat: 8:00 am – 6:00 pm 
                    </h6>

                    <iframe
                      className="pt-5"
                      src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3610.6351323523963!2d55.34531787538131!3d25.181795077720924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjXCsDEwJzU0LjUiTiA1NcKwMjAnNTIuNCJF!5e0!3m2!1sen!2sin!4v1691210967960!5m2!1sen!2sin"
                      width="100%"
                      height="350"
                      style={{ border: "0", opacity: "5" }}
                      allowFullScreen
                      loading="lazy"
                      title="Google Maps Location"
                      referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default ContactPage;
