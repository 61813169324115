import React from "react";
import Layout from "../Components/Layout/Layout";
import "../Style/BuildingPage.css";
import buildingImg5 from "../Assets/building5.jpg";
import buildingImg3 from "../Assets/building3.jpg";
import buildingImg9 from "../Assets/building9.jpg";
import buildingImg8 from "../Assets/building8.jpg";
import buildingImg7 from "../Assets/building7.jpg";
import buildingImg6 from "../Assets/building6.jpg";
import carparkdeckImg1 from "../Assets/carparkdeck1.jpg";
import carparkdeckImg from "../Assets/carparkdeck.png";
import buildingImg4 from "../Assets/building4.jpg";

function BuildingPage() {
  return (
    <Layout title={"Elegant Linings-Building & Infrastructure"}>
      <section style={{ paddingTop: "7rem" }}>
        <div className="pt-5 " style={{ backgroundColor: "gray" }}>
          <div className=" px-5 pb-2  ">
            <h4
              style={{ fontWeight: "700", color: "white" }}
              className="pt-3 px-5 "
            >
              Building and Infrastructure
            </h4>
          </div>
        </div>
      </section>
      <section
        className="pt-5 pb-5 image-building"
        style={{ minHeight: "65vh" }}
      >
        <div className="container pt-5 p-5">
          <div className="row">
            <h5
              className="pb-4 text-center"
              style={{ lineHeight: "1.9em", letterSpacing: "0.3px" }}
            >
              The Civil Engineering
            </h5>

            <div className="col-md-12 text-center">
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  The use of DESMOPOL or TECNOCOAT continuous waterproofing
                  systems on all types of civil engineering projects can reduce
                  and eliminate risks deriving from water exposure and
                  effectively prolong their active life. The application of
                  projectable membranes will provide lasting protection against
                  both climatic conditions and other elements. We have a
                  certificate for contact with foodstuff and drinking water, the
                  European certificate for protection of structural concrete
                  (EN-1504) and the ETA certificate for 25 years of working
                  life.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-5">
        <div className="container p-5">
          <div className="row">
            <div style={{ lineHeight: "1.9em" }} className="col-12 col-lg-6">
              <li>Bridges & viaducts</li>
              <li>Bus and train stations</li>
              <li>Ports & airports</li>
              <li>Public car parks</li>
              <li>Underground tunnels</li>
              <li>Water collectors</li>
              <li>Pumps & wells</li>
              <li>Sports grounds and installations</li>
              <li>Public buildings</li>
              <li>Parks and squares</li>
            </div>
            <div className="col-12 col-lg-6">
              <div className="">
                <img
                  src={buildingImg4}
                  width={"100%"}
                  height={"100%"}
                  alt="buildingImg4"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5">
        <div className="container p-5">
          <div className="row">
            <h5
              className="text-center pb-4"
              style={{ fontWeight: "900", textTransform: "uppercase" }}
            >
              Bridge Deck
            </h5>{" "}
            <hr className="pb-4" />
            <div className="col-md-12">
              <div>
                <p
                  style={{
                    fontSize: "0.9em",
                    lineHeight: "1.9em",
                    letterSpacing: "0.3px",
                  }}
                >
                  TECNOCOAT and DESMOPOL offer perfect waterproofing and
                  protection of concrete structures for civil engineering works.
                  In addition to being certified by the European Standard
                  EN-1504.2 for "Products and systems for the protection and
                  repair of concrete structures", it has recently obtained the
                  ETE certification according to the EOTA "Liquid applied
                  waterproofing system for bridge decks" (16/0680). In this way,
                  using our pure polyurea or polyurethane system, a total
                  protection of the concrete structures on bridge decks is
                  achieved, as well as the commissioning of the wearing course
                  (asphalt), avoiding the risk of damage, quickly, efficiently
                  and therefore reducing the costs of execution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5 carparkdeck-image">
        <div className="container p-5">
          <div className="row">
            <h5 style={{ fontWeight: "900", textTransform: "uppercase" }}>
              Car Park Deck
            </h5>

            <div className="col-12  col-lg-6">
              <div className="pt-4">
                <p
                  style={{
                    fontSize: "0.9em",
                    lineHeight: "1.9em",
                    letterSpacing: "0.3px",
                  }}
                >
                  We offer various system finishes for all types of vehicles and
                  conditions of use even on exposition to the weather and
                  particular conditions ( grease, oils…). The abrasion and skid
                  resistances are achieved by using natural or artificial
                  aggregates mixed with the colored topcoat. In essence, this
                  Car Park Deck Waterproofing system based on TECNOCOAT P-2049,
                  ensures the watertighness of any deck exposed to external
                  weather conditions (sun, rain, etc.), while providing a road
                  surface, with the minimum added weight, reducing previous
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pb-5 pt-4 ">
        <div className="container p-5">
          <div className="row">
            <h4 className="text-center pt-2 pb-2">Gallery</h4>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={carparkdeckImg}
                  width={"100%"}
                  height={"250rem"}
                  alt="carparkdeckImg"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={carparkdeckImg1}
                  width={"100%"}
                  height={"250rem"}
                  alt="carparkdeckImg1"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={buildingImg6}
                  width={"100%"}
                  height={"250rem"}
                  alt="buildingImg6"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={buildingImg7}
                  width={"100%"}
                  height={"250rem"}
                  alt="buildingImg7"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={buildingImg8}
                  width={"100%"}
                  height={"250rem"}
                  alt="buildingImg8"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={buildingImg9}
                  width={"100%"}
                  height={"250rem"}
                  alt="buildingImg9"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={buildingImg3}
                  width={"100%"}
                  height={"250rem"}
                  alt="buildingImg3"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={buildingImg5}
                  width={"100%"}
                  height={"250rem"}
                  alt="buildingImg5"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default BuildingPage;
