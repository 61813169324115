import React from "react";
import Layout from "../Components/Layout/Layout";
import "../Style/OilandGas.css";
import oilandgasImg8 from "../Assets/oilandgas8.jpg";
import oilandgasImg6 from "../Assets/oilandgas6.jpg";
import oilandgasImg5 from "../Assets/oilandgas5.jpg";
import oilandgasImg3 from "../Assets/oilandgas3.jpg";
import oilandgasImg14 from "../Assets/oilandgas14.jpg";
import oilandgasImg12 from "../Assets/oilandgas12.jpg";
import oilandgasImg13 from "../Assets/oilandgas13.jpg";
import oilandgasImg11 from "../Assets/oilandgas11.png";
import oilandgasImg10 from "../Assets/oilandgas10.jpg";
import oilandgasImg9 from "../Assets/oilandgas9.jpg";
import oilandgasImg2 from "../Assets/oilandgas2.jpg";
import oilandgasImg from "../Assets/oilandgas.jpg";

function OilandGas() {
  return (
    <Layout title={"Elegant Linings-Oil and Gas"}>
      <section style={{ paddingTop: "7rem" }}>
        <div className="pt-5 " style={{ backgroundColor: "gray" }}>
          <div className=" px-5 pb-2  ">
            <h4
              style={{ fontWeight: "700", color: "white" }}
              className="pt-3 px-5 "
            >
              Oil and Gas
            </h4>
          </div>
        </div>
      </section>
      <section
        className="pt-5 pb-5 image-oilandgas"
        style={{ minHeight: "65vh" }}
      >
        <div className="container pt-5 p-5">
          <div className="row">
            <h6
              className="pb-4 text-center"
              style={{ lineHeight: "1.9em", letterSpacing: "0.3px" }}
            >
              Elegant Linings-Rhino provides supply and apply of advanced
              polyurea products that offer excellent protective coating
              solutions for the oil and gas industry. There are lot of benefits
              of using polyurea for the oil and gas industry. It can be used
              immediately and withstand extreme weather conditions like snow,
              ice, rain and prolonged exposure to UV rays from the sun. This
              makes polyurea the ideal protective coating for any exterior
              surface and it can effectively protect oil and gas storage tanks,
              containers, and pipelines against abrasion, weather, and chemical
              corrosion, and extends the lifespan of these tanks and pipelines.
              polyurea is a durable advanced waterproofing and protective
              system.
            </h6>

            <div className="col-md-12 text-center">
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Polyurea coatings can be used to refurbish pipeline rollers
                  and interior tank linings to extend their lifespan and prevent
                  corrosion.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5">
        <div className="container  p-5">
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-6">
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  Polyurea can be used to establish primary and secondary
                  containment lining in storage tanks that will prevent
                  chemicals from leaking out. These enhanced safety features
                  protect oil and gas industry workers from exposure and risk of
                  explosions due to chemical interactions. It also protects the
                  surrounding environment from contamination.
                </p>
              </div>
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  When working on an oil and gas pipeline, some of the biggest
                  risk factors include corrosion, rust, chemical leaks, and
                  abrasion. To maintain strong safety standards and protect
                  workers from potential damages and injuries, polyurea coatings
                  can be applied as a protective layer in tank linings and
                  pipelines.
                </p>
              </div>
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  On oil and gas rigs, storage tanks are constantly being
                  exposed to potentially dangerous chemicals and weather
                  conditions. Lining the interior of storage tanks with a
                  durable polyurea coating can help control the internal
                  temperature, prevent pressure buildups that cause explosions,
                  and seal all cracks or other damages that can lead to harmful
                  chemical leaks.{" "}
                </p>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-lg-6">
              <div>
                <img
                  src={oilandgasImg}
                  width={"100%"}
                  height={"100%"}
                  alt="oilandgasImg"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5 bg-light">
        <div className="container p-5">
          <div className="row">
            <div className="col-sm-6 col-lg-6 ">
              <div
                className="pt-4"
                style={{ lineHeight: "1.9em", fontSize: "0.9em" }}
              >
                <li>Containment</li>
                <li>Corrosion & Degradation</li>
                <li>Abrasion, Wear & Impact</li>
                <li>Mechanical Chemical Exposure</li>
                <li>Noise and Vibration</li>
                <li>Water Penetration</li>
                <li>Blast Mitigation & Spalling</li>
                <li>Cathodic Disbondment</li>
                <li>Dielectric Resistance</li>
              </div>
            </div>
            <div className="col-sm-6 col-lg-6">
              <div className="pt-2">
                <img
                  src={oilandgasImg2}
                  width={"100%"}
                  height={"100%"}
                  alt="oilandgasImg2"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5">
        <div className="container p-5">
          <div className="row">
            <div className="col-md-12"></div>
            <div
              className="pt-5 text-center "
              style={{
                fontSize: "0.9em",
                lineHeight: "1.9em",
                letterSpacing: "0.3px",
              }}
            >
              <p>
                Our impermeable linings and coatings are installed to address a
                wide array of corrosion and safety problems in the oil and gas
                industry, including spills, fires and leaks that may contaminate
                the surrounding environment. The following are typical ways in
                which Lining the interior of storage tanks with a durable
                polyurea coating can help control the internal temperature,
                prevent pressure buildups that cause explosions. Linings are
                used in the field to increase safety, create longer-lasting
                equipment and reduce downtime:
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="image-oilandgas-second">
        <div className="container p-5">
          <div className="row">
            <div className="col-12 col-sm-12 col-lg-6">
              <ul style={{ lineHeight: "1.9em", fontSize: "0.9em" }}>
                <li>Storage Tanks and Pipes</li>
                <li>Containment Areas</li>
                <li>Drilling Rigs and Pumps</li>
                <li>Offshore Drilling Platforms</li>
                <li>Frac Ponds and Impoundments</li>
                <li>Oil Field Rig Matting and Flooring</li>
                <li> Pipeline Ditch Breakers</li>
              </ul>
            </div>

            <div className="col-12 col-sm-12 col-lg-6">
              <div className="row">
                <div
                  className=""
                  style={{
                    fontSize: "0.9em",
                    lineHeight: "1.9em",
                    letterSpacing: "0.3px",
                  }}
                >
                  <p>
                    Rhino Linings polyurethane and polyurea formulations offers
                    superior solutions for reliable, impervious primary
                    containment. The coatings have been specifically designed to
                    reduce primary containment maintenance costs and maximize
                    equipment return on investment.
                  </p>
                </div>
                <div
                  style={{
                    fontSize: "0.9em",
                    lineHeight: "1.9em",
                    letterSpacing: "0.3px",
                  }}
                >
                  <p>
                    Being sprayed or cast onto virtually any substrate (new or
                    in service) of any shape, regardless of penetrations, to any
                    required thickness in just one application is a huge time
                    saving advantage, another is the fact that all Rhino
                    formulations are tack free within minutes which reduces
                    expensive downtime.
                  </p>
                </div>
                <div
                  style={{
                    fontSize: "0.9em",
                    lineHeight: "1.9em",
                    letterSpacing: "0.3px",
                  }}
                >
                  <p>
                    If you are looking for protective coating that has been
                    purposely designed to be resistant to constant immersion and
                    harsh chemicals, RhinoChem 2170 is your solution.
                  </p>
                </div>
                <div
                  style={{
                    fontSize: "0.9em",
                    lineHeight: "1.9em",
                    letterSpacing: "0.3px",
                  }}
                >
                  <p>
                    Rhino’s industrial spray applied coatings for primary
                    containment are water, chemical and acid resistant and
                    capable of protecting storage tanks from corrosion and rust.
                    And as a sacrificial layer, these same coatings can provide
                    superior protection from harsh chemicals and biological
                    environments.
                  </p>
                  <p>
                    Rhino’s product formulations provide the ideal protective
                    solution against spills, leaks, or leaking hazardous
                    material seeping into the surrounding environment.{" "}
                  </p>
                  <p>
                    Rhino’s primary containment protective coatings will flex
                    and expand with substrate / thermal coefficient of change,
                    making them an ideal liner for petrochemical containment.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-12 pt-5">
              <div className="row">
                <h5 style={{ fontWeight: "600" }}>
                  Elegant Linings primary containment application
                </h5>
                <p
                  style={{
                    fontSize: "0.9em",
                    lineHeight: "1.9em",
                    letterSpacing: "0.3px",
                  }}
                >
                  Primary Storage Tanks, Steel Holding Tanks, Water Tanks, Frag
                  Tanks, Spray in Place Earthen Bunds, Acid Tank Protection,
                  Leaching Ponds, Tailings Dams
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pb-5 pt-5 ">
        <div className="container p-5">
          <div className="row">
            <h4 className="text-center pb-2">Gallery</h4>
            <div className="col-12 col-sm-6 col-lg-3 pt-4 ">
              <div className="">
                <img
                  src={oilandgasImg9}
                  width={"100%"}
                  height={"250rem"}
                  alt="oilandgasImg9"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={oilandgasImg10}
                  width={"100%"}
                  height={"250rem"}
                  alt="oilandgasImg10"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={oilandgasImg11}
                  width={"100%"}
                  height={"250rem"}
                  alt="oilandgasImg11"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={oilandgasImg13}
                  width={"100%"}
                  height={"250rem"}
                  alt="oilandgasImg13"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4">
              <div className="">
                <img
                  src={oilandgasImg12}
                  width={"100%"}
                  height={"250rem"}
                  alt="oilandgasImg12"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4 ">
              <div className="">
                <img
                  src={oilandgasImg14}
                  width={"100%"}
                  height={"250rem"}
                  alt="oilandgasImg14"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4 ">
              <div className="">
                <img
                  src={oilandgasImg3}
                  width={"100%"}
                  height={"250rem"}
                  alt="oilandgasImg3"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-3 pt-4 ">
              <div className="">
                <img
                  src={oilandgasImg5}
                  width={"100%"}
                  height={"250rem"}
                  alt="oilandgasImg5"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 pt-4 ">
              <div className="">
                <img
                  src={oilandgasImg6}
                  width={"100%"}
                  height={"250rem"}
                  alt="oilandgasImg6"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 pt-4 ">
              <div className="">
                <img
                  src={oilandgasImg8}
                  width={"100%"}
                  height={"250rem"}
                  alt="oilandgasImg8"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default OilandGas;
