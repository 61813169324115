import React from "react";
import { useRef } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, NavLink } from "react-router-dom";
import Logo from "../../Assets/logowitharabic.jpg"

function Header() {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (


    <>
      <header>
        <Link to={"/"} style={{ textDecoration: "none" }}>
          <h3
            className="heading"
            style={{ color: "#0e2b18", cursor: "pointer" }}
          >
            <img className="logo-image" src={Logo}  alt="" /><br />
         
          </h3>
        </Link>

        <nav ref={navRef} className="ms-auto">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About Us</NavLink>
          <NavLink to="/product">Our Products</NavLink>
          <NavLink to={"/service"}>Our Services</NavLink>
          <NavLink to="/contact">Contact Us</NavLink>

          <button className="nav-btn nav-close-btn" onClick={showNavbar}>
            <FaTimes />
          </button>
        </nav>

        <button className="nav-btn" onClick={showNavbar}>
          <FaBars />
        </button>
      </header>
    </>
  );
}

export default Header;
