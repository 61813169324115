import React from 'react'
import { Link } from 'react-router-dom'
import {SiWhatsapp,SiFacebook,SiInstagram,SiLinkedin,SiYoutube} from 'react-icons/si'


function Footer() {
  return (
    <div className='footer' >
      <div className="container" >
        <div className="row">
          <div className="col-sm-12 col-lg-12  mb-4 " >
            
           
              <div style={{ lineHeight: '1.8em', listStyle: 'none' }}>
                <li className='pt-3' style={{ fontWeight: 'bolder',letterSpacing:'0.1rem' }} >
                  Elegant Linings Techical Services L.L.C</li>
                <li>Ras Al Khor Industrial Area 2</li>
                <li>WH#16, Al Arif Compound, Street 7</li>
                <li>Dubai,UAE</li>
                <li>TEL: <Link to={"tel:+97145474832"} style={{ textDecoration: 'none', color: 'whitesmoke' }}>+971 4 547 4832</Link></li>
                <li><Link style={{ textDecoration: 'none', color: 'whitesmoke' }}>elegantlinings@gmail.com</Link></li>
                <li><Link to={"https://www.elegantlinings.com"} style={{ textDecoration: 'none', color: 'whitesmoke' }}>elegantlinings.com</Link></li>
              </div>
          </div>


        </div>
      </div>
      <div className="container ">
        <div className="row">
          <div className="col-12 col-sm-6 col-lg-6 pt-4">
          <p className='' style={{  letterSpacing: '2px', textTransform: 'uppercase', fontWeight: '' }}>Elegant Linings | <span style={{ fontFamily: 'sans-serif', letterSpacing: '1.5px', fontSize: '13px', textTransform: 'lowercase' }}>elegantlinings.com  Copyright 2023 | All Rights Reserved</span> </p>

          </div>
          <div className="col-12 col-sm-6 col-lg-6">
  <div className='footer-logo'>
          <Link to={"https://api.whatsapp.com/send?phone=971544896181"} style={{margin:'0.9rem',fontSize:'1.2rem'}}>
          <SiWhatsapp style={{textDecoration:'none',color:'white'}}/>
          </Link>
          <Link to={"https://www.facebook.com/Elegantliningsuae"}  style={{margin:'0.9rem',fontSize:'1.2rem'}}>
          <SiFacebook style={{textDecoration:'none',color:'white'}}/>
          </Link>
          <Link to={"https://www.instagram.com/elegantlinings/"} style={{margin:'0.9rem',fontSize:'1.2rem'}}>
          <SiInstagram style={{textDecoration:'none',color:'white'}}/>
          </Link>
          <Link to={"https://www.linkedin.com/company/elegantliningsuae/"} style={{margin:'0.9rem',fontSize:'1.2rem'}}>
          <SiLinkedin style={{textDecoration:'none',color:'white'}}/>
          </Link>
          <Link to={"https://www.youtube.com/@ELEGANTLININGS"} style={{margin:'0.9rem',fontSize:'1.2rem'}}>
          <SiYoutube style={{textDecoration:'none',color:'white'}}/>
          </Link>
          
        
        
        </div>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Footer