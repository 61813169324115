import React from "react";
import Layout from "../Components/Layout/Layout";
import "../Style/WasteWaterPage.css";
import wastewaterImg4 from "../Assets/wastewater4.jpg";
import wastewaterImg7 from "../Assets/wastewater7.jpg";
import wastewaterImg8 from "../Assets/wastewater8.jpg";
import wastewaterImg9 from "../Assets/wastewater9.JPG";
import wastewaterImg2 from "../Assets/wastewater2.jpg";
import wastewaterImg1 from "../Assets/wastewater1.jpg";
import wastewaterImg6 from "../Assets/wastewater6.jpg";

function WasteWaterPage() {
  return (
    <Layout title={"Elegant Linings-Water and Waste Water"}>
      <section style={{ paddingTop: "7rem" }}>
        <div className="pt-5 " style={{ backgroundColor: "gray" }}>
          <div className=" px-5 pb-2  ">
            <h4
              style={{ fontWeight: "700", color: "white" }}
              className="pt-3 px-5 "
            >
              Water and Wastewater
            </h4>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5 image-water" style={{ minHeight: "65vh" }}>
        <div className="container pt-5 p-5">
          <div className="row">
            <h6
              className="pb-4 text-center"
              style={{ lineHeight: "1.9em", letterSpacing: "0.3px" }}
            >
              {" "}
              <p>
                Elegant Linings quality application with polyurea is one of the
                best waterproofing solutions for swimming pools. This
                high-strength elastomer is one of the most effective materials
                for spaces where the continuous passage of water can cause
                leaks, such as fountains, water parks and terraces. It is widely
                used in new buildings but also in repairs, which are carried out
                quickly and efficiently. Polyurea for swimming pools offers
                total water tightness and excellent adhesion to the substrate
                polyurea is also UV-resistant.{" "}
              </p>
            </h6>

            <div className="col-md-12 text-center">
              <div
                style={{
                  fontSize: "0.9em",
                  lineHeight: "1.9em",
                  letterSpacing: "0.3px",
                }}
              >
                <p>
                  If it can corrode, fail, or leak, it will. That’s why water
                  and wastewater treatment facilities globally have chosen Rhino
                  Linings polyurethane-polyurea coatings as their protection
                  solution.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-5">
        <div className="container p-5">
          <div className="row">
            <div
              className=" col-sm-12 col-lg-6"
              style={{
                fontSize: "0.9em",
                lineHeight: "1.9em",
                letterSpacing: "0.3px",
              }}
            >
              <div>
                <p>
                  Elegant Linings offers excellent stability for immersion
                  applications and chemical resistance. It has a superior rigid
                  formulation with the highest amount of molecular
                  cross-linking. Elegant Linings-Rhino Hi-Chem provides
                  excellent corrosion resistance and will not deteriorate from
                  most chemical attacks and is ideal for wastewater treatment
                  facilities.
                </p>
              </div>
              <div className="" style={{ lineHeight: "2.5em" }}>
                <li>
                  Tack free within seconds of application with modifiable cure
                  times available
                </li>
                <li>Can be sprayed or cast to any desired thickness</li>
                <li>
                  Versatility – formulations can be sprayed to virtually any
                  substrate including: concrete, steel, metals, fiberglass, wood
                  and geo-textile surfaces
                </li>
                <li>
                  Delivers superior corrosion protection and resistance against
                  adverse chemistries such as gasoline, JP3, diesel, crude oil,
                  condensates, brine water, hydroxides, solvents, peroxides,
                  salts, lye and various other harsh chemicals.
                </li>
              </div>
            </div>
            <div className=" col-sm-12 col-lg-6">
              <div className="">
                <img
                  src={wastewaterImg4}
                  width={"100%"}
                  height={"100%"}
                  alt="wastewaterImg4"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="benefit-image pt-5 pb-5">
        <div className="container p-5">
          <div className="row">
            <div className="col-12 col-lg-12  ">
              <div className=" pt-2 ">
                <h4 className="text-center">BENEFITS</h4>
                <hr className="pt-2 pb-4" />
                <div style={{ lineHeight: "1.9em", fontSize: "0.9em" }}>
                  <li>
                    Reduces maintenance costs, repairs and equipment downtime
                  </li>
                  <li>Increases personnel safety and productivity</li>
                  <li>Adds years of life to any surface</li>
                  <li>
                    Cushions cargo and prevents damage from shifting during
                    transit
                  </li>
                  <li>
                    Shields against abrasion, impacts, and abuse from the
                    elements
                  </li>
                  <li>
                    Lower production costs than traditional coating solutions
                  </li>
                  <li>Environmentally friendly, no VOCs or solvents</li>
                  <li>Won’t crack, peel, split or warp</li>
                  <li>UV resistant, no yellowing</li>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pt-5 pb-5 bg-light">
        <div className="container p-5">
          <div className="row">
            <h4 className="text-center pb-2">Gallery</h4>
            <div className="col-12 col-md-3 pt-4">
              <div className="">
                <img
                  src={wastewaterImg7}
                  width={"100%"}
                  height={"250rem"}
                  alt="wastewaterImg7"
                />
              </div>
            </div>
            <div className="col-12 col-md-3 pt-4">
              <div className="">
                <img
                  src={wastewaterImg8}
                  width={"100%"}
                  height={"250rem"}
                  alt="wastewaterImg8"
                />
              </div>
            </div>
            <div className="col-12 col-md-3 pt-4">
              <div className="">
                <img
                  src={wastewaterImg9}
                  width={"100%"}
                  height={"250rem"}
                  alt="wastewaterImg9"
                />
              </div>
            </div>
            <div className="col-12 col-md-3 pt-4">
              <div className="">
                <img
                  src={wastewaterImg2}
                  width={"100%"}
                  height={"250rem"}
                  alt="wastewaterImg2"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 pt-4">
              <div className="">
                <img
                  src={wastewaterImg1}
                  width={"100%"}
                  height={"250rem"}
                  alt=" wastewaterImg1"
                />
              </div>
            </div>
            <div className="col-12 col-sm-6 col-lg-6 pt-4">
              <div className="">
                <img
                  src={wastewaterImg6}
                  width={"100%"}
                  height={"250rem"}
                  alt="wastewaterImg6"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default WasteWaterPage;
