import React from "react";
import Layout from "../Components/Layout/Layout";
import "../Style/AboutPage.css";
import AboutImg from "../Assets/About.png";

function AboutPage() {
  return (
    <Layout title={"Elegant Linings-About Us"}>
      <section style={{ paddingTop: "7em" }}>
        <div className="pt-5 " style={{ backgroundColor: "rgb(17, 64, 69)" }}>
          <div className=" px-5 pb-2 pt-2  ">
            <h4
              style={{ fontWeight: "700", color: "white" }}
              className="pt-3 px-5"
            >
              About us
            </h4>
          </div>
        </div>
      </section>
      <section className="pt-5 pb-5">
        <div className="container-fluid  p-5   pb-5">
          <div className="row ">
            <h4
              className=" pb-4 text-center"
              style={{ fontWeight: "900", color: "gray" }}
            >
              Welcome to Elegant Linings
            </h4>
            <hr className="pt-4" />
            <div className="col-xl-4 ">
              <img
                src={AboutImg}
                className="pt-3"
                width={"100%"}
                height={"320px"}
                alt=""
              />
            </div>
            <div className="col-xl-8  ">
              <div
                className="pt-2"
                style={{ lineHeight: "1.9rem", fontSize: "0.9em" }}
              >
                <p>
                  We,{" "}
                  <span style={{ fontWeight: "900" }}>
                    Elegant Linings Technical Services LLC
                  </span>
                  , The Company located in the Ras Al Khor industrial area
                  2.Dubai,United Arab Emirates.{" "}
                </p>
                <p>
                  <span style={{ fontSize: "18px", fontWeight: "700" }}>
                    Elegant Linings{" "}
                  </span>{" "}
                  is specialized in the application for Polyurea protective
                  coating and Linings, PU foam Insulation, Epoxy, Polyaspartic
                  floor coating systems and our team provides total quality
                  management system and service. Elegant Linings team are highly
                  professional and technically qualified more than 20 year
                  experience in the industry Automotive, Building and
                  Construction, Oil and Gas, Marine, Agriculture, defense and
                  security in the middle east, offers year's round 24/7 advanced
                  technical support and training backed by dedicated superior
                  technical service staff with years of experience.
                </p>
                <p>
                  Elegant Linings is one of the leading supplier and applicator
                  of Polyurea, PU foam, Floor coatings, Topcoat, Primer, Wire
                  Trim Tapes, Spray Machinery, Spare parts and Accessories. Our
                  supplier provides highest quality products for industrial,
                  residential, commercial, purpose. All products are tested and
                  certified by authorities.
                </p>
              </div>
              <p style={{ fontSize: "12px" }}>Team Elegant Linings</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default AboutPage;
